.blog {

  // @media (max-width: 700px) {
  //   padding: 20px;
  // }

  .blog-news-container {

    @media (max-width: 700px) {
      display: flex;
      flex-direction: column;

      p {
        max-width: 400px;
      }
       
      img {
        max-width: 400px;
      }
    }
  }

  .margin-bottom {
    margin-bottom: 30px;
  }


  .blog-container {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-right: 50px;
    padding-left: 40px;

      @media (max-width: 700px) {
        padding-left: 20px;
  }
  }

  .blog-container-txt {
    max-width: 360px;
    font-size: 16px;
    font-weight: 400;
    line-height: 19.36px;
    text-align: left;
    color: #04163D;
  }

  .blog-title {
    font-size: 32px;
    font-weight: 400;
    line-height: 38.73px;
    text-align: left;


  }
}