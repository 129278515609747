.home {
  margin-top: 50px;

  .home-head-docs-imgs {
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    @media screen and (max-width: 1200px) {
      align-items: start;
      gap: 20px;
      flex-direction: row;
      justify-content: start;
    }
  }

  .padding-left {
    padding-left: 30px;
  }

  .home-head-blue-box {

    width: 400px;
    height: 200px;
    border-radius: 20px;
    background-color: #caf0f8;
    color: #020887;
    font-size: 10px;
    font-weight: 700;
    line-height: 12.1px;
    text-align: left;
    display: flex;
    align-items: left;
    flex-direction: column;
    justify-content: center;
    position: relative;

    @media (max-width: 1200px) {
      width: 252px;
      height: 119px;
    }
  }

  .patients-number {
    font-size: 20px;
    font-weight: 700;
    line-height: 24.2px;
    color: #020887;
    text-align: center;
    max-width: 150px;
    margin-bottom: 0;

    @media (max-width: 1200px) {
      font-size: 10px;
      line-height: 12.1px;
      text-align: left;
      margin-left: 10px;
    }
  }

  .main-services-box {
    display: flex;
    gap: 20px;
    align-items: start;
  }

  .main-services-box-container {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }

  .choose-us {
    text-align: left;
    color: #020887;
    font-size: 48px;
    font-weight: 400;
    line-height: 70.62px;
  }

  .main-services-box-bottom {

    p {
      text-align: left;
      font-size: 16px;
      font-weight: 400;
      line-height: 29.4px;
      text-align: left;
      margin-top: 0;
    }

    .main-services-box-title {
      color: #020887;
      font-weight: 700;
      margin-bottom: 0;
      margin-top: 0;
    }

    .main-services-box-txt {
      margin-top: 0;
    }

  }


  .approve-patients {
    font-size: 10px;
    font-weight: 700;
    line-height: 12.1px;
    color: #020887;
    text-align: center;
    max-width: 150px;

    @media (max-width: 1200px) {
      font-size: 6px;
      line-height: 9.7px;
      text-align: left;
      margin-left: 10px;
    }
  }

  .home-head-blue-box-bottom {
    margin-top: 30px;
    position: relative;

    @media screen and (max-width: 1200px) {
      margin-top: 0;

    }
  }

  .homenatiabg {
    position: absolute;
    left: 40px;
    z-index: -1;

    @media (max-width: 1050px) {
      display: none;
    }
  }

  .desktop-natia {
    @media (max-width: 1050px) {
      display: none;
    }
  }

  .homedoctor-img {
    position: absolute;
    bottom: 0;
    right: -20px;

    @media (max-width: 1200px) {
      display: none;
    }
  }

  .modemedicmobile {
    display: none;

    @media (max-width: 1200px) {
      display: block;
      width: 202px;
      height: 154px;
      position: absolute;
      bottom: 0;
      right: -20px;
    }
  }


  .homeheart-img {
    position: absolute;
    bottom: -50px;
    right: 0;

    @media (max-width: 1200px) {
      display: none;
    }
  }

  .homedoctormobile {
    display: none;

    @media (max-width: 1200px) {
      display: block;
      width: 202px;
      height: 154px;
      position: absolute;
      right: -40px;
    }
  }

  .clinic-container-imgs-box {
    display: flex;
    flex-wrap: wrap;
    gap: 35px;
    margin-bottom: 70px;
  }

  .clinic-container-imgs {
    width: 306px;
    height: 56px;
    border-radius: 12px;
    border: 1px solid #e6e6e6;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .home-about-box-right {
    position: relative;

    @media (max-width: 900px) {
      display: none;
    }
  }

  .home-about-txt-main {
    margin-top: 0;

    @media (max-width: 700px) {
      margin-top: 0;
    }
  }

  .home-about-container-child {
    display: flex;
    gap: 30px;
    padding-bottom: 40px;
    padding-top: 30px;

    @media (max-width: 1050px) {
      flex-direction: column;
    }

    @media (max-width: 700px) {
      gap: 0;
    }
  }



  .your-health {
    font-family: Inter;
    font-size: 48px;
    font-weight: 400;
    line-height: 70.62px;
    text-align: left;
    color: #FFFFFF;
    margin-top: 30px;
    margin-bottom: 0;
    margin-left: 560px;

    @media (max-width: 1050px) {
      margin-left: 0;
      font-size: 28px;
      font-weight: 400;
    }

    @media (max-width: 900px) {
      margin-left: 0;
      font-size: 24px;
      font-weight: 400;
    }
  }

  .home-about-container {
    margin-top: 50px;
    background-color: #020887;
    padding-left: 30px;

    @media (max-width: 1380px) {
      padding-bottom: 80px;
    }

    @media (max-width: 1270px) {
      padding-bottom: 120px;
    }

    @media (max-width: 1195px) {
      padding-bottom: 200px;
    }

    @media (max-width: 1050px) {
      padding-bottom: 40px;
    }

    @media (max-width: 900px) {
      height: 100%;
      flex-direction: column;
    }

    @media (max-width: 750px) {
      padding-bottom: 70px;
    }

    @media (max-width: 700px) {
      padding-bottom: 80px;
    }

    @media (max-width: 600px) {
      padding-bottom: 100px;
    }

    @media (max-width: 540px) {
      padding-bottom: 150px;
    }

    @media (max-width: 480px) {
      padding-bottom: 280px;
    }

    @media (max-width: 400px) {
      padding-bottom: 520px;
    }
  }

  .video-img {
      width: 99vw;

    // @media (max-width: 700px) {
    //   width: 100%;
    // }
  }

  .video-img,
  .swiper-slide {
    @media (max-width: 700px) {
      height: 250px;
      width: auto;
    }

    @media (max-width: 600px) {
      height: 300px;
      width: auto;
    }
  }

  .direction-items {
    width: 256px;
    height: 181px;
    background-color: #f4f4f4;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
  }

  .direction-items-bottom {
    background-color: #647aa3;
    width: 256px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #FFFFFF;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    font-weight: 700;
    p {
      margin-top: 0;
    }

  }


  .home-about-title {
    font-weight: 400;
    font-size: 24px;
    color: #020887;
  }

  .home-about-txt {
    font-weight: 400;
    font-size: 20px;

    @media (max-width: 1195px) {
      font-size: 18px;
    }

    @media (max-width: 800px) {
      font-size: 16px;
    }
  }

  .home-about-box {
    max-width: 800px;
    min-height: 386px;
    border-radius: 20px 0px 0px 0px;
    text-align: left;
    padding: 20px;
    border-radius: 20px;
    height: 400px;
    color: #FFFFFF;

    @media (max-width: 1050px) {
      padding-left: 0;
    }


    @media (max-width: 940px) {
      padding-top: 0;
    }
  }

  .partners-title {
    font-weight: 400;
    font-size: 24px;
    color: #647AA373;
    text-align: left;
  }

  .home-news {
    font-size: 48px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: #020887;
    margin-top: 50px;
  }

  .home-head-box {
    display: flex;
    gap: 50px;
    position: relative;
    padding-right: 30px;

    @media (max-width: 1200px) {
      flex-direction: column;
    }
  }

  .news-container {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-right: 50px;
    margin-bottom: 30px;
  }

  .news-container-txt {
    max-width: 360px;
    font-family: Inter;
    font-size: 16px;
    font-weight: 700;
    line-height: 19.36px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    margin-top: 5px;
  }

  .natia-name {
    font-family: Jost;
    font-size: 32px;
    font-weight: 400;
    line-height: 46.24px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: white;
    margin-bottom: 0;
    margin-top: 0;
  }

  .natia-profession {
    font-family: Jost;
    font-size: 15px;
    font-weight: 400;
    line-height: 21.68px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #cdc6c6d4;
    margin-top: 0;
  }

  .video-section {
    position: relative;
    width: 100%;
    max-width: 1400px;
    margin: 0 auto;
    overflow: hidden;
    border-radius: 10px;
  }

  .background-video {
    width: 100%;
    height: auto;
    object-fit: cover;
  }

  .video-overlay-text {
    position: absolute;
    top: 20%;
    left: 10%;
    transform: translate(0, -50%);
    color: white;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
    z-index: 2;
    max-width: 50%;
  }

  .border-img {
    border-radius: 10px;
    overflow: visible;
  }

  .directions-container {
    display: flex;
    gap: 60px;
    flex-wrap: wrap;
    justify-content: center;
  }

  .video-title-box {
    position: absolute;
    background-color: white;
    padding: 20px;
    color: #001f6e;
    font-size: 40px;
    line-height: 1.5;
    max-width: 600px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

    @media (max-width: 1500px) {
      font-size: 35px;
    }

    @media (max-width: 1200px) {
      font-size: 30px;
    }

    p {
      margin: 0;
      text-align: left;
    }
  }

  .homenatia-img {
    margin-top: 20px;
    margin-right: 20px;

    @media (max-width: 1050px) {
      display: none;
    }
  }

  .video-overlay-text h1 {
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 1rem;
  }

  .video-overlay-text p {
    font-size: 1.2rem;
    line-height: 1.5;
  }

  .video-title {
    color: #020887;
  }

  .natiamobile-box {
    display: none;

    @media (max-width: 1050px) {
      display: flex;
      flex-direction: column;
      justify-content: start;
      max-width: 600px;
      margin-right: 40px;
    }

    @media (max-width: 750px) {
      max-width: 450px;
    }
  }


  .slider-container {
    width: 100%;
    padding: 20px 0;
    background-color: #fff;
  }

  .slider-item {
    text-align: center;
    padding: 10px;
  }

  .slider-item {

    img {
      width: 20vw;
      border-radius: 20px;

      @media (max-width: 950px) {
        width: 60vw;
      }
    }

  }

  .home-big-img-bg {
    background-image: url('../../images/homeblock2.svg');
    background-repeat: no-repeat;
    width: 95vw;
    height: 40vw;
    border-radius: 20px;
    background-size: cover;
    background-position: center;
    position: relative;
    margin-left: 20px;
    margin-top: 40px;

    img {
      width: 95vw;
      height: 40vw;
      object-fit: cover;
      border-radius: 20px;

    }

    .home-big-img-bg-txt {
      position: absolute;
      bottom: 50px;
      background-color: rgba(255, 253, 253, 0.35);
      width: 494px;
      left: 20px;
      height: 278px;
      border-radius: 20px;
      font-size: 55px;
      display: flex;
      flex-direction: column;
      justify-content: center;

      @media (max-width: 1250px) {
        font-size: 45px;
      }

      @media (max-width: 1080px) {
        width: 400px;
        height: 230px;
        bottom: 20px;
      }

      
      @media (max-width: 930px) {
        width: 350px;
        height: 200px;
        bottom: 20px;
      }

      @media (max-width: 650px) {
        width: 200px;
        height: 100px;
      }

      p {
        margin: 0;
        text-align: left;
        display: flex;
        align-items: center;
        margin-left: 25px;
        color: rgba(255, 255, 255, 1);

        @media (max-width: 1250px) {
          font-size: 45px;
        }

        @media (max-width: 930px) {
          font-size: 35px;
        }
        @media (max-width: 650px) {
          font-size: 20px;
        }
      }

    
    }

    .home-big-img-bg-txt-right {
      font-size: 11px;
      position: absolute;
      bottom: 50px;
      right: 20px;

      @media (max-width: 1050px) {
        bottom: 20px;

      }

      @media (max-width:  850px) {
        display: none;

      }

      p { 
        max-width: 420px;
        text-align: left;
        color: rgba(255, 255, 255, 1);
      }

      img {
        width: 58px;
        height: 58px;
      }

      .home-big-img-bg-txt-right-bottom {
        background-color: rgba(217, 217, 217, 0.35);
        width: 200px;
        height: 85px;
        border-radius: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-left: 20px;
        padding-right: 20px;

        @media (max-width: 1050px) {
          padding-right: 20px;

        }


      }

      .home-big-img-bg-txt-right-bottom-head-text {
        max-width: 350px;
      }

      .home-patients-number {
        font-size: 24px;
        font-weight: 700;
        margin: 0;
      }
    }


  }

}