@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Raleway:ital,wght@0,100..900;1,100..900&display=swap');

.invitro {
  font-family: "Inter", sans-serif;

}

.blog-txt-head {
  height: 86px;
  background-color: #03045E;
  display: flex;
  justify-content: start;
  align-items: center;
  padding-left: 80px;
  font-family: "Inter", sans-serif;

  @media screen and (max-width: 1200px) {
    padding-left: 40px;
  }
}

.invitroblog {
  @media screen and (max-width: 1200px) {
  max-width: 580px;
    
  }
}

.blog-post-start {
  display: flex;
  gap: 30px;
  margin-top: 30px;

  @media screen and (max-width: 1200px) {
    display: flex;
    flex-direction: column;
    gap: 30px;
    
  }
}

.blog-content {
  padding-left: 80px;
  padding-right: 30px;
  font-size: 24px;
  font-weight: 300;
  line-height: 29.05px;
  text-align: left;
  color: #000000;

  @media screen and (max-width: 1200px) {
    padding-left: 30px;    
  }

  @media screen and (max-width: 800px) {
    font-size: 14px;  
  }
}

.blog-post-start-right {
  text-align: left;
  max-width: 718px;

  @media screen and (max-width: 1200px) {
    max-width: none;
    
  }
}

.blog-post-start-head {
font-size: 24px;
font-weight: 700;
line-height: 29.05px;
text-align: left;
}

.blog-main-txt-head {
font-size: 32px;
font-weight: 400;
line-height: 38.73px;
text-align: left;
text-underline-position: from-font;
text-decoration-skip-ink: none;
color: #FFFFFF;

@media screen and (max-width: 800px) {
  font-size: 14px;  
}
}

.blog-main-txt-head-sub {
font-size: 16px;
font-weight: 700;
line-height: 19.36px;
text-align: left;
color: #8181af;

}

.invitroimg {
    max-width: 80vw;
}