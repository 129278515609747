
.header {
  display: flex;
  justify-content: space-between;
  padding-left: 30px;
  padding-right: 50px;

  @media (max-width: 500px) {
    padding-left: 10px;
    padding-right: 10px;

  }

  @media (max-width: 1200px) {
    justify-content: start;
    gap: 50px;
    padding-right: 30px;
  }
}

.burger-menu-bottom-box {
  display: flex;
  gap: 20px;
  align-items: center;
font-size: 14px;
font-weight: 400;
line-height: 16.94px;
text-align: left;
color: white;

}

.modal-header-pages {
  color: white;
  text-align: left;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-top: 20px;
}


.header-right-images {
  z-index: 10;
}


.burger-menu-open {
  background-color: #020887;
  height: 90vh;
  position: absolute;
  width: 100%;
  z-index: 100;
  margin-top: 40px;
  padding-left: 50px;
  display: flex;
  flex-direction: column;
  padding-bottom: 300px;
  justify-content: space-between;
}

.header-logo-img {
  @media (max-width: 1140px) {
    max-width: 240px;
    margin-top: 10px;
  }
}

.header-logo-mobile {
  max-height: 40px;
}

.contact-header {
  @media (max-width: 1000px) {
    display: none;
  }
}

.header-mobile {
  display: none;

  @media (max-width: 1000px) {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
}

.header-mobile-right {
  display: flex;
  gap: 20px;
  align-items: center;
}

.heder-left {
  display: flex;
  gap: 120px;

  @media (max-width: 1200px) {
    gap: 90px;

  }

  @media (max-width: 1140px) {
    gap: 40px;
    align-items: center;
  }

  @media (max-width: 1000px) {
    display: none;
  }
}

.header-pages {
  display: flex;
  gap: 20px;
  color: #020887;

  p {
    cursor: pointer;
  }
}

.header-right {
  display: flex;
  gap: 50px;

  @media (max-width: 1000px) {
    display: none;
  }
}

.header-right-images {
  display: flex;
  gap: 20px;
  align-items: center;
}

.header-pages {
  .active-route {
    color: #000CFF; 
    cursor: auto;
  }
}

.blue-header {
  color: #020887;
}
