.services-page {}

.services-text-box {
  display: flex;
  gap: 20px;
  align-items: center;
  padding-left: 40px;
}

.services-main-title {
  background-color: #03045E;
  height: 86px;
  padding-left: 40px;
  color: #ffffff;
  display: flex;
  justify-content: start;
  align-items: center;
  font-family: Inter;
  font-size: 32px;
  font-weight: 400;
  line-height: 38.73px;

}

.services-list-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 40px;
}

.services-txt {
  font-size: 16px;
  font-weight: 400;
  line-height: 19.2px;
  color: #000000;
  text-align: left;
}

.services-text-ul {
  text-align: left;
  margin-top: 0;
  margin-left: 50px;

  li {
    font-family: Itim;
    font-size: 16px;
    font-weight: 400;
    line-height: 19.2px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #808080;

  }
}