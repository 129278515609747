.footer {
  background-color: #03045e;
  position: relative;
  height: 200px;
  padding: 50px;
  display: flex;
  justify-content: space-between;
  align-items: start;
  padding-right: 150px;

  @media screen and (max-width: 990px) {
    flex-direction: column;
    gap: 40px;
    height: auto;
  }

  .logofoot {
    position: absolute;
  }

  .footer-left {
    display: flex;
    gap: 50px;
    align-items: start;
  }

  .footer-left-main-txt {
    margin-top: 0;
  }


  .footer-left-main-box {
    display: flex;
    flex-direction: column;
    color: white;
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.94px;
    text-align: left;

    @media screen and (max-width: 990px) {
      display: none;
      
    }
  }

  .footer-left-img-block {
    display: flex;
    gap: 20px;
  }

  .footer-left-logo-box {
    display: flex;
    flex-direction: column;
    gap: 30px;

    @media screen and (max-width: 990px) {
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      width: 100%;
    }
  }

  .mobile-footer-contact {
    display: none;

    @media screen and (max-width: 990px) {
      display: flex;
      gap: 20px;
      align-items: center;
      justify-content: space-between;
      width: 100%;
    }
  }
  

  .facebookfooter-img {

    @media screen and (max-width: 990px) {
      width: 200px;
      height: 25px;
    }
  }

  .footer-right-box-contact-txt {
    margin-top: 0;
  }

  .footer-right-box-contact {
    font-size: 14px;
    font-weight: 400;
    line-height: 16.94px;
    color: white;
    display: flex;
    flex-direction: column;
    gap: 5px;

    @media screen and (max-width: 990px) {
      display: none;
    }
  }

  .mobile-footer-contact-left {
    display: flex;
  }

  .mobile-footer-contact-right {
    display: flex;
    gap: 20px;
  }

.footer-bottom-mobile {
  display: flex;
  gap: 20px;
  align-items: center;

  p {
    margin: 0;
  }
}




}